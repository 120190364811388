import axios, { AxiosRequestConfig } from 'axios';
import { PagedResponse } from '../types/api';
import { CreditCard } from '../types/CreditCard';

const URL = '/v2/credit-cards';

class CreditCardService {
  static findByFilter(
    config: AxiosRequestConfig
  ): Promise<PagedResponse<CreditCard>> {
    return axios.get(`${URL}`, config);
  }

  static delete(id: number, config?: AxiosRequestConfig) {
    return axios.delete(`${URL}/${id}`, config);
  }

  static create(creditCard: CreditCard, config?: AxiosRequestConfig): Promise<PagedResponse<CreditCard>> {
    return axios.post(URL, creditCard, config);
  }
}

export { CreditCardService };
