import axios, { AxiosRequestConfig } from 'axios';
import { StripeVerification } from '../types/StripeVerification';
import { DataResponse, PagedResponse } from '../types/api';

const URL = '/v2/stripe-verification';
const PAYMENT_INTENT_URL = '/create-payment-intent';

class StripeVerificationService {
  static get(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<StripeVerification>> {
    return axios.get(`${URL}/${id}`, config);
  }

  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<StripeVerification>> {
    return axios.get(URL, config);
  }

  static patch(
    id: number,
    data: StripeVerification,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<StripeVerification>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static create(
    data: StripeVerification,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<StripeVerification>> {
    return axios.post(URL, data, config);
  }

  static createPaymentIntent(
    data: StripeVerification,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<StripeVerification>> {
    return axios.post(URL+PAYMENT_INTENT_URL, data, config);
  }
}

export { StripeVerificationService };
